import { Container, makeStyles, Typography } from '@material-ui/core'
import React, { FC } from 'react'

import { SEO } from '@/components/SEO'

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    marginTop: spacing(8),
    marginBottom: spacing(2),
  },
}))

const NotFoundPage: FC = () => {
  const classes = useStyles()

  return (
    <Container maxWidth="md" className={classes.root}>
      <SEO title="404: Not found" />
      <Typography variant="h2" gutterBottom component="h1">
        NOT FOUND
      </Typography>
      <Typography variant="body1">
        You just hit a route that doesn&#39;t exist... the sadness.
      </Typography>
    </Container>
  )
}

export default NotFoundPage
